import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/App.css';
import ScrollTop from './utils/scrollTop';
import Main from './components/Main/Main';

function App() {

  const apiUrl = process.env.REACT_APP_API_URL;
  const mainUrl = process.env.REACT_APP_LANDING;
  const webUrl = process.env.REACT_APP_LANDING_WEB;
  const advUrl = process.env.REACT_APP_LANDING_ADV;

  return (

    <BrowserRouter>

    <div className="app">

      <ScrollTop />
      
      <Main apiUrl={apiUrl} webUrl={webUrl} advUrl={advUrl}/>

    </div>
    
    </BrowserRouter>
  
  );
}

export default App;

import { Link } from 'react-router-dom'
import m from './Main.module.scss'
import '../../style/index.css'

import { useState } from 'react'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Header from '../Header/Header'
import Footer from '../footer/footer' 

import rekMan from '../../img/rek__item.svg'
import rekWom from '../../img/rek__itemTwo.svg'
import rekItem1 from '../../img/item1.svg'
import rekItem2 from '../../img/item2.svg'
import rekItem3 from '../../img/item3.svg'
import rekItem4 from '../../img/item4.svg'
import cursor from '../../img/cursor.svg'
import infoBg from '../../img/info-bg.png'
import infoBgTwo from '../../img/info-bgTwo.png'
import cursorImage from '../../img/slider/item1.svg';
import cursorImage2 from '../../img/slider/item2.svg';
import cursorImage3 from '../../img/slider/item3.svg';
import cursorImage4 from '../../img/slider/item4.svg';
import cursorImage5 from '../../img/slider/item5.svg';
import cursorImage6 from '../../img/slider/item6.svg';


export default function Main ({apiUrl, webUrl, advUrl}) {



      const settings = {
        autoplay: true,
        autoplaySpeed: 1000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false, 
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
        adaptiveHeight: true, // Адаптивная высота для избежания проблем с высотой слайдов
      };

      const settingsSlider = {
        autoplay: true,
        autoplaySpeed: 1000,
        dots: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        arrows: false,
      
        responsive: [
          {
            breakpoint: 1224,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
        adaptiveHeight: true, // Адаптивная высота для избежания проблем с высотой слайдов
      };

      const logos = [
        require('./logos/true.svg').default,
        require('./logos/noon.svg').default,
        require('./logos/true.svg').default,
        require('./logos/noon.svg').default,
        require('./logos/true.svg').default,
        require('./logos/noon.svg').default,

      ];

      const slider = [

        {
          imgSrc: cursorImage,
          title: 'Уникальный каталог',
          subtitle: 'Используйте уникальный каталог рекламных площадок с индексом соответствия аудитории',
        },

        {
          imgSrc: cursorImage2,
          title: 'Настройки аудитории',
          subtitle: 'Детализированные настройки аудитории'
        },

        {
          imgSrc: cursorImage3,
          title: 'ОРД',
          subtitle: 'Автоматическая регистрация рекламы в ОРД'
        },

        {
          imgSrc: cursorImage4,
          title: 'Индекс соответствия аудитории',
          subtitle: 'Мы используем новейшие технологии для подбора площадок, исходя из вашей целевой аудитории.'
        },

        {
          imgSrc: cursorImage5,
          title: 'Удобная система оплаты и выплат',
          subtitle: 'Множество возможностей для физических и юридических лиц.'
        },

        {
          imgSrc: cursorImage6,
          title: 'Внутренний антифрод',
          subtitle: 'Механизм блокировки работает на опережение и моментально останавливает подозрительный трафик с площадки. Безопасность ваших бюджетов на 100%.'
        },

        {
          imgSrc: cursorImage5,
          title: 'Средняя цена клика в динамической рекламе:',
          subtitle: 'от 1 рубля до 30 рублей'
        },

      ]
      

      const signup = () => {
        window.open(`${apiUrl}/auth/signup`, '_blank')
      }

      const advertiser = () => {
        window.open(`${apiUrl}/auth/signup?_reg_type=advertiser`, '_blank')
      }

      const webmaster = () => {
        window.open(`${apiUrl}/auth/signup?_reg_type=webmaster`, '_blank')
      }
      


    return (

        <>

       <Header apiUrl={apiUrl}/>

       <section className={m.section__main}>
        <div className="container">
          
          <p className={m.main}>
            
            <p className={m.main__title}>
            Крутая реклама
            начинается с Qrooto!
            </p>

            <p className={m.main__subtitle}>
            Рекламная платформа будущего
            </p>

          </p>

        </div>
       </section>

       <section className={m.section__rek}>
        <div className="container">
          
          <div className={m.rek}>
            
            <div className={m.rek__item}>
              
              <p className={m.rek__item__subtitle}>
              Вебмастерам
              </p>

              <p className={m.rek__item__title}>
              Выгодная цена за клики или фиксированную стоимость
              </p>

              <Link to={`https://${webUrl}/`} target='_blank' className={m.rek__item__btn}>
              Вебмастерам
              </Link>

              <img src={rekMan} alt="img" className={m.rek__item__img} />
              
              <div className={m.rek__item__fon}></div>

            </div>

            <div className={[m.rek__item, m.rek__itemTwo].join(' ')}>
              
              <p className={m.rek__item__subtitle}>
              Рекламодателям
              </p>

              <p className={m.rek__item__title}>
              Эффективный трафик только на интересующих вас площадках
              </p>

              <Link to={`https://${advUrl}/`} className={m.rek__item__btn} target='_blank'>
              Рекламодателям
              </Link>

              <img src={rekWom} alt="img" className={m.rek__item__img} />
              
              <div className={m.rek__item__fonTwo}></div>

            </div>

          </div>

          <div className={m.rek__banner}>
            
            <div className={m.rek__banner__item}>
              
              <p className={m.rek__banner__item__title}>
              Статистика платформы
              </p>

              <div className={m.rek__banner__item__flex}>
                
                {/* <div className={m.rek__banner__item__flex__item}>

                  <img src={rekItem1} className={m.rek__banner__item__flex__item__icon} alt="img" />
                  
                  <p className={m.rek__banner__item__flex__item__subtitle}>
                  Клики
                  </p>

                  <p className={m.rek__banner__item__flex__item__title}>
                  817 
                  </p>

                </div>

                <div className={[m.rek__banner__item__flex__item , m.rek__banner__item__flex__itemTwo].join(' ')}>

                  <img src={rekItem2} className={m.rek__banner__item__flex__item__icon} alt="img" />
                  
                  <p className={m.rek__banner__item__flex__item__subtitle}>
                  Размещений куплено
                  </p>

                  <p className={m.rek__banner__item__flex__item__title}>
                  1
                  </p>

                </div> */}

                <div className={[m.rek__banner__item__flex__item , m.rek__banner__item__flex__itemthree].join(' ')}>

                  <img src={rekItem3} className={m.rek__banner__item__flex__item__icon} alt="img" />
                  
                  <p className={m.rek__banner__item__flex__item__subtitle}>
                  Вебмастеров
                  </p>

                  <p className={m.rek__banner__item__flex__item__title}>
                  1000+
                  </p>

                </div>

                <div className={[m.rek__banner__item__flex__item , m.rek__banner__item__flex__itemfour].join(' ')}>

                  <img src={rekItem4} className={m.rek__banner__item__flex__item__icon} alt="img" />
                  
                  <p className={m.rek__banner__item__flex__item__subtitle}>
                  Рекламодателей
                  </p>

                  <p className={m.rek__banner__item__flex__item__title}>
                  100+
                  </p>

                </div>

              </div>

            </div>

            <div className={m.rek__banner__info}>

              <p className={m.rek__banner__info__title}>
              Станьте частью новой рекламной платформы
              </p>

              <p className={m.rek__banner__info__subtitle}>
              Вступайте в закрытый клуб партнёров и обменивайтесь премиальным трафиком
              </p>

            </div>

          </div>

        </div>
       </section>

       <section className={m.section__slider} id='platform'>
        
        <div className="container">
          
          <p className={m.slider__title}>
          Преимущества рекламной платформы
          </p>

          <p className={m.slider__subtitle}>
Продвигайте свой сайт и продавайте рекламные места в одном личном кабинете
          </p>
          
          <div className={m.slider}>
            
    <Slider {...settingsSlider}>

      {slider.map((item, index) => (

        <div className={m.slider__item} id={index}>
  
          <img src={item.imgSrc} style={{width: '40px'}} alt="" />
          
          <p className={m.slider__item__title}>
          {item.title}
          </p>

          <p className={m.slider__item__subtitle}>
            {item.subtitle}
          </p>

        </div>

      ))}

    </Slider>


          </div>



        </div>

       </section>

       <section className={m.section__info} id='dyn'>
        
        <div className="container">
          
          <div className={m.info}>

            <img src={infoBg} className={m.info__img} alt="" />
            
            <div className={m.info__item}>
            
            <p className={m.info__item__title}>
            Динамика
            </p>

            <p className={m.info__item__subtitle}>
            Система размещения рекламы на сайтах и в мобильных приложениях с оплатой за клик, в которой рекламодатель контролирует и отслеживает показатели каждой площадки, а вебмастер - рекламу, которая крутится у него на сайте. С нами выгодно!
            </p>

            <Link target='_blank' to={`${apiUrl}/auth/signup?_reg_type=webmaster`} className={m.info__item__btn}>
            Запустить рекламу
            </Link>

            </div>

          </div>

        </div>

       </section>

       <section className={m.section__infoTwo} id='stat'>
        
        <div className="container">
          
          <div className={m.infoTwo}>


            <div className={m.info__item}>
            
            <p className={m.info__item__title}>
            Статика
            </p>

            <p className={m.info__item__subtitle}>
            Уникальный маркетплейс площадок, готовых размещать рекламодателей за фиксированную стоимость. От крупнейших вебсайтов до каналов TG. Договаривайтесь не через личного менеджера, а напрямую в чате вашего личного кабинета.
            </p>

            <Link target='_blank' to={`${apiUrl}/auth/signup?_reg_type=advertiser`} className={m.info__item__btn}>
            Запустить рекламу
            </Link>

            </div>

            <img src={infoBgTwo} className={m.info__img} alt="" />

          </div>

        </div>

       </section>

        <section className={m.section__top}>
            
            {/* <div className={m.top__fon}></div> */}

            <div className="container">
                
                <p className={m.top__title}>
                Нас выбирают
                </p>

                <div className={m.top__logos}>

                <div className={m.gradientOverlay} />

    <Slider {...settings}>

      {logos.map((logo, index) => (

        <div key={index} className={m.top__logos__imgContainer}>
          <img src={logo} alt={`img-${index}`} style={{width: '120px', height: '50px'}} className={m.top__logos__img} />
        </div>

      ))}

    </Slider>

    <div className={m.gradientOverlayTwo} />

                </div>

            </div>
        </section>

        <Footer apiUrl={apiUrl}/>
        
        </>



    )
}